import { useEffect, useState } from 'react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  bodyCopySizeTheme as BODY_COPY_SIZE,
  getThemeStyles,
  headingSizeTheme as HEADING_SIZE,
} from 'holded/lib/styles';
import { getCustomTag, isDefault } from 'holded/lib/utils';
import { TrustpilotReview } from 'holded/modules/cms/domain/collection/trustpilotReview';
import { Testimonials4Section } from 'holded/modules/cms/domain/components/testimonial';
import { defaultThemeValues, TestimonialTheme } from 'holded/modules/cms/ui/sections/testimonials/TestimonialTheme';
import 'swiper/css';
import 'swiper/css/navigation';
import HeroIcon from 'holded/modules/cms/ui/shared/components/HeroIcon';
import NextImage from 'holded/modules/cms/ui/shared/components/Image';

SwiperCore.use([Navigation, Autoplay]);

const TestimonialCard = ({
  attributes: { title, description, image, name, dateAndLocation, value },
}: TrustpilotReview) => {
  return (
    <div className="bg-white rounded-2xl p-10 h-full shadow-xl my-10">
      <p className="text-xl leading-7 font-semibold text-gray-dark h-16 mb-2">{title}</p>
      <div className={'h-32'}>
        <p className="text-base leading-6 font-normal text-gray line-clamp-4">{description}</p>
      </div>
      <div className="flex items-center">
        {[...Array(value)].map((star, index) => {
          return (
            <span key={'star-' + index}>
              <HeroIcon icon="star" size={8} outline={false} extraClass="text-green-500 mr-2" />
            </span>
          );
        })}
      </div>
      <div className="flex items-center mt-8">
        {image?.data && (
          <div className="inline-flex h-12 w-12">
            <NextImage media={image.data} classImg={'h-12 w-12 rounded-full object-cover'} />
          </div>
        )}
        <div className="ml-3 h-16">
          <p className="text-sm leading-5 font-medium text-gray-dark">{name}</p>
          <p className="text-sm leading-5 font-normal text-gray">{dateAndLocation}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials4 = ({
  title,
  titleSecondary,
  description,
  trustpilotReviews,
  background,
  customTag,
}: Testimonials4Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  const CustomTag = getCustomTag('h3', customTag?.headingTag);
  const defaultHeadingSize = isDefault(customTag?.headingSize);
  const defaultBodySize = isDefault(customTag?.bodyCopySize);

  const headingSize = `text-h4 md:${HEADING_SIZE[customTag?.headingSize ?? 'default']}`;

  const breakpoints = {
    '640': { slidesPerView: 1 },
    '800': { slidesPerView: 3 },
  };

  useEffect(() => {
    const themeStyles = getThemeStyles(TestimonialTheme.testimonial, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div className={`${themeValues.background}`}>
      <div className="max-w-7xl mx-auto md:pb-4 pt-6">
        <div className="text-center pt-6 pb-10">
          <p className="text-base leading-6 font-semibold tracking-wider uppercase text-green-500 mb-1">
            {titleSecondary}
          </p>
          <CustomTag
            className={`${
              defaultHeadingSize ? 'text-h4 md:text-h3' : headingSize
            } leading-10 font-extrabold tracking-tight ${themeValues.headerTextColor}`}
          >
            {title}
          </CustomTag>
          <p
            className={`${
              defaultBodySize ? 'text-b2' : BODY_COPY_SIZE[customTag?.bodyCopySize ?? 'default']
            } leading-7 font-normal ${themeValues.descriptionTextColor} mt-3`}
          >
            {description}
          </p>
        </div>
        <Swiper
          className="w-full"
          spaceBetween={10}
          loop={true}
          loopedSlides={4}
          autoplay={{ delay: 2000, disableOnInteraction: false, pauseOnMouseEnter: true }}
          breakpoints={breakpoints}
        >
          {trustpilotReviews?.data?.map((review) => {
            return (
              <SwiperSlide key={review.attributes.name} className={'px-1 lg:px-3'}>
                <TestimonialCard {...review} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials4;
